import algoliasearch from "algoliasearch/lite"
import { default as React, Fragment, useState, useMemo } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { orderBy } from "lodash"
import {
  InstantSearch,
  Pagination,
  RefinementList,
  ClearRefinements,
  ScrollTo,
  // CurrentRefinements,
} from "react-instantsearch-dom"
import SearchBox from "./search-box"
import SearchResult from "./search-result"
import "../../styles/search.css"
import { Dialog, Disclosure, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/outline"
import { FilterIcon, MinusSmIcon, PlusSmIcon } from "@heroicons/react/solid"
import TimeZoneToggle from "../time-zone-toggle"

const filters = [
  { index: "topics.title", name: "Topics" },
  { index: "type.title", name: "Type" },
  { index: "format", name: "Format" },
]

export default function Search({ indices }) {
  const data = useStaticQuery(graphql`
    {
      wpMenu(name: { eq: "Schedule" }) {
        menuItems {
          nodes {
            uri
            target
            label
          }
        }
      }
    }
  `)
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const [query, setQuery] = useState()
  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY
      ),
    []
  )
  const menuItems = data?.wpMenu?.menuItems?.nodes
  const [displayLocalTimeZone, setDisplayLocalTimeZone] = useState(false)

  return (
    <section className="" key={indices}>
      <div>
        <InstantSearch
          searchClient={searchClient}
          indexName={indices[0].name}
          onSearchStateChange={({ query }) => setQuery(query)}
        >
          <div className="md:grid md:grid-cols-3 lg:grid-cols-4 relative">
            <div className="hidden md:block relative">
              <SearchFilters
                menuItems={menuItems}
                setMobileFiltersOpen={setMobileFiltersOpen}
              />
            </div>
            {/* Mobile filter dialog */}
            <Transition.Root show={true} as={Fragment}>
              <Dialog
                as="div"
                className={`relative md:hidden ${
                  mobileFiltersOpen ? "z-40" : "-z-10 invisible"
                }`}
                onClose={setMobileFiltersOpen}
                open={mobileFiltersOpen}
                static
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 flex z-40">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl flex flex-col overflow-y-auto">
                      <SearchFilters
                        menuItems={menuItems}
                        setMobileFiltersOpen={setMobileFiltersOpen}
                      />
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>
            <div className="md:col-span-2 lg:col-span-3 bg-blue-50/30 p-8">
              <div className="flex flex-wrap justify-between items-center">
                <div className="filters-date -ml-2 sm:-ml-3">
                  <RefinementList
                    className="my-2"
                    attribute="date"
                    limit={100}
                    transformItems={items => orderBy(items, "label", "asc")}
                  />
                </div>

                <TimeZoneToggle
                  enabled={displayLocalTimeZone}
                  setEnabled={setDisplayLocalTimeZone}
                />
              </div>
              <ScrollTo>
                <SearchResult
                  indices={indices}
                  displayLocalTimeZone={displayLocalTimeZone}
                />
              </ScrollTo>
              <Pagination />
            </div>
            <button
              type="button"
              className="md:hidden w-full sticky bottom-0 uppercase font-bold tracking-wider inset-x-0 bg-blue hover:bg-blue-600 transition flex items-center justify-center text-white px-8 py-6"
              onClick={() => setMobileFiltersOpen(true)}
            >
              <FilterIcon
                className="w-5 h-5 text-white mr-3"
                aria-hidden="true"
              />
              Filters
            </button>
          </div>
        </InstantSearch>
      </div>
    </section>
  )
}

function SearchFilters({ setMobileFiltersOpen, menuItems }) {
  return (
    <div className="filters-main px-8 sticky top-0 h-screen overflow-y-auto">
      <div className="h-full flex flex-col justify-between py-8">
        <div>
          <button
            type="button"
            className="ml-auto md:hidden w-10 h-10 p-2 rounded-md flex items-center justify-center text-gray-400 mb-4 -mt-4"
            onClick={() => setMobileFiltersOpen(false)}
          >
            <span className="sr-only">Close menu</span>
            <XIcon className="h-6 w-6" aria-hidden="true" />
          </button>
          <SearchBox />
          {/* <CurrentRefinements /> */}
          <ClearRefinements className="my-4" />
          {filters.map(section => (
            <Disclosure
              as="div"
              key={section}
              className="border-t border-gray-200 py-6"
            >
              {({ open }) => (
                <>
                  <h3 className="-mx-2 -my-3 flow-root">
                    <Disclosure.Button className="px-2 py-3 bg-white w-full flex items-center justify-between text-gray-400 hover:text-gray-500">
                      <span className="font-medium text-gray-900">
                        {section.name}
                      </span>
                      <span className="ml-6 flex items-center">
                        {open ? (
                          <MinusSmIcon className="h-5 w-5" aria-hidden="true" />
                        ) : (
                          <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
                        )}
                      </span>
                    </Disclosure.Button>
                  </h3>

                  <Disclosure.Panel
                    className={`pt-2 ${
                      open
                        ? ""
                        : "h-0 absolute pointer-events-none -z-10 invisible"
                    }`}
                    static
                  >
                    <div className="">
                      <RefinementList
                        className="mt-2"
                        attribute={section.index}
                        limit={100}
                        transformItems={items => orderBy(items, "label", "asc")}
                      />
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </div>
        {menuItems?.length ? (
          <nav className="mt-auto">
            <ul className="mt-12 space-y-2">
              {menuItems?.map(menuItem => {
                return (
                  <li>
                    <Link
                      className="uppercase font-bold tracking-wider text-gray-700 hover:text-blue-500"
                      to={menuItem.uri}
                    >
                      {menuItem.label}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </nav>
        ) : null}
      </div>
    </div>
  )
}
