import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import parse from "html-react-parser"
import { UserCircleIcon } from "@heroicons/react/outline"
import Image from "./image"

export default function Speakers({ props }) {
  const data = useStaticQuery(graphql`
    {
      allWpSpeaker(
        sort: { fields: title, order: ASC }
        filter: {
          speaker: { showOnSpeakersPage: { eq: true } }
        }
      ) {
        speakers: nodes {
          title
          speaker {
            titleOrganizationCountry
            attendance
          }
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 256)
                }
                extension
                publicURL
              }
            }
          }
        }
      }
    }
  `)
  const speakers = data?.allWpSpeaker?.speakers
  return (
    <section key={data} className="container mt-12 mb-20">
      {speakers && (
        <ul className="grid gap-y-6 xl:gap-y-8 gap-x-8 sm:gap-x-10 lg:gap-x-12 xl:gap-x-16 2xl:gap-x-28 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 my-10">
          {speakers?.map(speaker => {
            const image = speaker?.featuredImage?.node
            return (
              <>
                <li className="">
                  {image ? (
                    <Image
                      image={image}
                      objectFit="cover"
                      objectPosition="top"
                      imgClassName="rounded-lg"
                      className="max-w-[10rem] sm:max-w-none w-full h-40 shadow-spread bg-gray-200 rounded-lg"
                    />
                  ) : (
                    <UserCircleIcon className="max-w-[10rem] sm:max-w-none w-full h-40 shadow-spread bg-gradient-to-br from-blue-200/20 via-blue-100/30 to-gray-200 rounded-lg text-gray-400/70 p-11" />
                  )}
                  <div className="mt-3">
                    <SpeakerInfo speaker={speaker} />
                  </div>
                </li>
              </>
            )
          })}
        </ul>
      )}
     
    </section>
  )
}
function SpeakerInfo({ speaker }) {
  return (
    <>
      {speaker.title && (
        <div className="font-medium">{parse(speaker.title)}</div>
      )}
      {speaker.speaker.titleOrganizationCountry && (
        <div className="mb-1 text-sm">
          {parse(speaker.speaker.titleOrganizationCountry)}
        </div>
      )}
      {speaker.speaker.attendance &&
        speaker.speaker.attendance !== "Hidden" &&
        speaker.speaker.attendance !== "hidden" && (
          <div className="text-gray-600 uppercase text-xs tracking-wider">
            {parse(speaker.speaker.attendance)}
          </div>
        )}
    </>
  )
}
