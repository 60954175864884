import React from "react"
import parse from "html-react-parser"
import Image from "./image"
import { UserCircleIcon } from "@heroicons/react/outline"

export default function Team({ props }) {
  const { heading, teamGroups } = props
  const imgClassMd = "sm:grid-cols-3"
  return (
    <>
      <section className="container mt-12 mb-20">
        {heading && <h2>{parse(heading)}</h2>}
        {teamGroups && (
          <ul className="">
            {teamGroups?.map(teamGroup => {
              const { heading, teamMembers } = teamGroup
              return (
                <li className="my-10">
                  {heading && <h3>{parse(heading)}</h3>}
                  {teamMembers && (
                    <ul
                      className={`grid gap-y-10 gap-x-12 my-8 items-start max-w-2xl ${imgClassMd}`}
                    >
                      {teamMembers?.map(teamMember => {
                        const image =
                          teamMember?.teamMember?.featuredImage?.node
                        const name = teamMember?.teamMember?.name
                        return (
                          <>
                            <li className="">
                              {image ? (
                                <Image
                                  image={image}
                                  objectFit="cover"
                                  objectPosition="top"
                                  imgClassName="rounded-lg"
                                  className="max-w-[10rem] w-full h-40 shadow-spread bg-gray-200 rounded-lg"
                                />
                              ) : (
                                <UserCircleIcon className="max-w-[10rem] w-full h-40 shadow-spread bg-gradient-to-br from-blue-200/20 via-blue-100/30 to-gray-200 rounded-lg text-gray-400/70 p-11" />
                              )}

                              <>
                                <div className="mt-3">
                                  {name && (
                                    <div className="font-medium">
                                      {parse(name)}
                                    </div>
                                  )}
                                  {teamMember.title && (
                                    <div className="">
                                      {parse(teamMember.title)}
                                    </div>
                                  )}
                                </div>
                              </>
                            </li>
                          </>
                        )
                      })}
                    </ul>
                  )}
                </li>
              )
            })}
          </ul>
        )}
      </section>
    </>
  )
}
