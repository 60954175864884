import React from "react"
import parse from "html-react-parser"
import Image from "./image"

export default function Sponsors({ props }) {
  const { sponsorGroups } = props
  const imgClassMd =
    "sm:grid-cols-3"
  return (
    <>
      <section className="container mt-10 mb-20">
        {sponsorGroups && (
          <ul className="my-10">
            {sponsorGroups?.map(sponsorGroup => {
              const { heading, sponsors } = sponsorGroup
              return (
                <>
                  {heading && <h2>{parse(heading)}</h2>}
                  {sponsors && (
                    <ul className={`grid gap-y-10 gap-x-12 my-10 items-center justify-center ${imgClassMd}`}>
                      {sponsors?.map(sponsor => {
                        const image = sponsor?.featuredImage?.node
                        return (
                          <>
                            <li className="">
                              {image ? (
                                <Image
                                  image={image}
                                  objectFit="contain"
                                  objectPosition="top"
                                  className="w-auto h-auto max-h-32 mr-2 lg:mr-3"
                                />
                              ) : (
                                <>
                                  <div className="">
                                    {sponsor.title && (
                                      <div className="font-medium">
                                        {parse(sponsor.title)}
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                            </li>
                          </>
                        )
                      })}
                    </ul>
                  )}
                </>
              )
            })}
          </ul>
        )}
      </section>
    </>
  )
}
