import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "../styles/rooms-sessions.css"

export default function RoomsSessions() {
  const data = useStaticQuery(graphql`
    {
      allWpSession(
        sort: { order: ASC, fields: session___dateTime___startTime }
      ) {
        nodes {
          title
          uri
          sessionInfo: session {
            dateTime {
              startTime
            }
            location {
              physicalLocation
            }
            format
          }
        }
      }
    }
  `)
  const dates = ["June 24", "June 25"]
  const times = ["08:30", "10:30", "13:00", "15:00", "17:00"]
  const rooms = ["A1B", "A2", "S9", "S8", "S7", "S6", "S4", "S3", "S2", "S1"]
  const types = [
    { label: "Hybrid", abbr: "H", bg: "bg-orange-100" },
    { label: "In-person", abbr: "P", bg: "bg-purple-100" },
    { label: "Virtual", abbr: "V", bg: "bg-green-100" },
    {
      label: "No session at the time in the indicated room",
      abbr: "N",
      bg: "bg-white",
    },
  ]
  const sessions = data?.allWpSession?.nodes
  function findSession(date, room, time) {
    return sessions.find(
      session =>
        session.sessionInfo?.location?.physicalLocation === "Room " + room &&
        session.sessionInfo?.dateTime?.startTime === date + ", " + time
    )
  }
  return (
    <section className="my-12">
      <div className="container">
        <div className="font-bold mb-2">Table Legend</div>
        <ul className="w-40 mb-8 border border-gray-800">
          {types.map(type => {
            return (
              <li
                className={`p-3 bg-orange-100 border-gray-800 border ${type.bg}`}
              >
                <span className="bg-gray-800/10 p-2 rounded-full inline-block font-bold border-collapse leading-none mr-2">
                  {type.abbr}
                </span>
                {type.label}
              </li>
            )
          })}
        </ul>
      </div>
      <div className="mx-auto px-8">
        {dates?.map(date => {
          return (
            <div className="mb-12">
              <div className="overflow-y-auto">
                <table className="table-rooms-sessions border-collapse border-b-2 border-l-2 border-r-2 border-gray-600 text-sm">
                  <caption className="relative px-2 py-2 font-bold text-left lg:text-center text-xl bg-gray-100 border-l-2 border-r-2 border-t-2 border-gray-600">
                    <span className="sticky left-2">{date}</span>
                  </caption>
                  <thead>
                    <tr className="">
                      <th className="font-bold uppercase bg-gray-50 sticky -left-1">
                        Room
                      </th>
                      {rooms?.map(room => {
                        return (
                          <th className="font-bold uppercase bg-gray-50">
                            {room}
                          </th>
                        )
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {times?.map(time => {
                      return (
                        <tr>
                          <th
                            style={{ borderRight: "0" }}
                            className="font-bold sticky -left-1 bg-white z-10"
                          >
                            {time}
                          </th>
                          {rooms?.map(room => {
                            const session = findSession(date, room, time)
                            return (
                              <td
                                className={`text-sm 2xl:text-base relative ${
                                  session?.sessionInfo?.format === "Hybrid"
                                    ? "bg-orange-100 hover:bg-orange-200"
                                    : session?.sessionInfo?.format ===
                                      "In-person"
                                    ? "bg-purple-100 hover:bg-purple-200"
                                    : session?.sessionInfo?.format === "Virtual"
                                    ? "bg-green-100 hover:bg-green-200"
                                    : ""
                                }`}
                              >
                                {session ? (
                                  <Link
                                    className={`link-body link-stretched`}
                                    to={session.uri}
                                  >
                                    <div className="text-right mt-1 mb-2">
                                      <span className="bg-gray-800/10 p-2 rounded-full inline-block font-bold leading-none">
                                        {session?.sessionInfo?.format ===
                                        "Hybrid"
                                          ? "H"
                                          : session?.sessionInfo?.format ===
                                            "In-person"
                                          ? "P"
                                          : session?.sessionInfo?.format ===
                                            "Virtual"
                                          ? "V"
                                          : ""}
                                      </span>
                                    </div>

                                    {session.title}
                                  </Link>
                                ) : (
                                  <div className="text-right self-end mt-1 mb-2">
                                    <span className="bg-gray-800/10 p-2 rounded-full inline-block font-bold leading-none">
                                      N
                                    </span>
                                  </div>
                                )}
                              </td>
                            )
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}
