import React from "react"
import Content from "./content"
import RoomsSessions from "./rooms-sessions"
import Search from "./search"
import Speakers from "./speakers"
import Sponsors from "./sponsors"
import Team from "./team"
const searchIndices = [{ name: `Sessions`, title: `Sessions` }]

const Sections = ({ sections }) => {
  if (!sections || !sections.length) {
    return <div></div>
  }

  return (
    <>
      {sections.map(section => {
        const fieldGroupName = section?.fieldGroupName
        if (fieldGroupName) {
          return fieldGroupName.includes("Customsections_Sections_Schedule") ? (
            <Search indices={searchIndices} props={section} />
          ) : fieldGroupName.includes("Customsections_Sections_Speakers") ? (
            <Speakers props={section} />
          ) : fieldGroupName.includes("Customsections_Sections_Sponsors") ? (
            <Sponsors props={section} />
          ) : fieldGroupName.includes("Customsections_Sections_Team") ? (
            <Team props={section} />
          ) : fieldGroupName.includes("Customsections_Sections_Content") ? (
            <Content props={section} />
          ) : fieldGroupName.includes(
              "Customsections_Sections_RoomsAndSessions"
            ) ? (
            <RoomsSessions props={section} />
          ) : null
        } else {
          return null
        }
      })}
    </>
  )
}

export default Sections
