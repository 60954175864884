import React from "react"
import parse from "html-react-parser"

export default function Content({ props }) {
  const { content } = props
  return (
    <>
      {content ? (
        <section className="container mt-8 mb-12" key={content}>
          <div className="parsed-content overflow-x-auto">
            {parse(content)}
          </div>
        </section>
      ) : null}
    </>
  )
}
