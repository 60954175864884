import { Link } from "gatsby"
import { default as React } from "react"
import { Highlight, Hits, Index } from "react-instantsearch-dom"
import {
  CalendarIcon,
  VideoCameraIcon,
  LocationMarkerIcon,
  BookmarkIcon,
  UserCircleIcon,
  ChatAltIcon,
} from "@heroicons/react/outline"
import parse from "html-react-parser"
import Image from "../image"

// const HitCount = connectStateResults(({ searchResults }) => {
//   const hitCount = searchResults && searchResults.nbHits

//   return hitCount > 0 ? <span className="HitCount">({hitCount})</span> : null
// })
let lastTimeMarker = 0
let lastDateMarker = 0
let displayLocalTimeZoneGlobal = false

const PageHit = ({ hit }) => {
  let location = hit.location?.physicalLocation
  location =
    location?.split(" ")?.length > 3
      ? location?.split(" ")?.slice(0, 3)?.join(" ") + "..."
      : location

  let speakerCount = 0

  const startDateTime = hit.start ? new Date(hit.start + " GMT+0200") : null
  const timeOptions = { hour: "2-digit", minute: "2-digit" }
  const dateOptions = {
    month: "long",
    day: "numeric",
  }
  const timeZoneOptions = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    timeZoneName: "short",
  }
  const localStartDate = startDateTime.toLocaleDateString("en-US", dateOptions)
  const localStartTime = startDateTime.toLocaleTimeString("en-GB", timeOptions)

  let localTimeZone = startDateTime.toLocaleTimeString("en-GB", timeZoneOptions)
  localTimeZone = localTimeZone.substring(localTimeZone.indexOf(" ") + 1)

  let startTime = hit.start?.split(",")?.slice(1)
  const newTimeMarker = lastTimeMarker !== hit.start
  lastTimeMarker = newTimeMarker ? hit.start : lastTimeMarker

  const date = displayLocalTimeZoneGlobal ? localStartDate : hit.date
  const newDateMarker = lastDateMarker !== date
  lastDateMarker = newDateMarker ? date : lastDateMarker

  return (
    <div className={`my-3`}>
      {newDateMarker && (
        <div className={`mt-6 -mb-5 text-lg font-bold`}>
          <span>{displayLocalTimeZoneGlobal ? localStartDate : hit.date}</span>
        </div>
      )}
      {newTimeMarker && (
        <div className={`my-6 flex items-center text-lg`}>
          <span>{displayLocalTimeZoneGlobal ? localStartTime : startTime}</span>
          <div className="border-b border-gray-400 flex-grow ml-4"></div>
        </div>
      )}
      <div className="ml-10 sm:ml-16">
        <Link className="" to={hit.slug}>
          <div className="relative p-4 lg:p-6 rounded-lg bg-white hover:shadow-spread">
            <div className="flex flex-wrap uppercase text-xs tracking-wider text-gray-600 space-y-1 -mx-4 lg:-mx-6 mb-3">
              {location && (
                <div className="flex items-center mx-4 lg:mx-6">
                  {hit.format === "In-person" || hit.format === "Hybrid" ? (
                    <LocationMarkerIcon className="w-5 h-5 mr-1 text-gray-500" />
                  ) : (
                    <>
                      <VideoCameraIcon className="w-5 h-5 mr-1 text-gray-500" />{" "}
                      Virtual -{" "}
                    </>
                  )}
                  {parse(location)}
                  {hit.format === "Hybrid" || hit.format === "Virtual"
                    ? " & Online"
                    : null}
                </div>
              )}
              {hit.start && (
                <div className="flex items-center mx-4 lg:mx-6">
                  <CalendarIcon className="w-5 h-5 mr-1 text-gray-500" />
                  <>
                    {displayLocalTimeZoneGlobal ? (
                      <>
                        {localStartDate}
                        {localStartDate && localStartTime ? ", " : ""}
                        {localStartTime} {localTimeZone}
                      </>
                    ) : (
                      <>{hit.start} CEST</>
                    )}
                  </>
                </div>
              )}
              {hit.type && (
                <div className="flex items-center mx-4 lg:mx-6">
                  <ChatAltIcon className="w-5 h-5 mr-1 text-gray-500" />
                  {hit.type[0].title}
                </div>
              )}
            </div>
            <h3
              itemProp="name"
              className="font-bold text-base lg:text-lg leading-normal text-gray-700"
            >
              <Highlight attribute="title" hit={hit} tagName="mark" />
            </h3>
            {hit.speakerGroups ? (
              <>
                <ul className="gap-x-6 gap-y-3 grid lg:grid-cols-3 2xl:grid-cols-4 max-w-3xl 2xl:max-w-none mt-3 mb-2">
                  {hit.speakerGroups?.map(speakerGroup => {
                    return (
                      <>
                        {speakerGroup?.speakers?.map(speaker => {
                          let name = speaker.title
                          name =
                            name?.split(" ")?.length > 4
                              ? name?.split(" ")?.slice(0, 4)?.join(" ") + "..."
                              : name
                          let title = speaker.speaker.titleOrganizationCountry
                          title =
                            title?.split(" ")?.length > 6
                              ? title?.split(" ")?.slice(0, 6)?.join(" ") +
                                "..."
                              : title
                          speakerCount++
                          const image = speaker?.featuredImage?.node
                          return (
                            <>
                              {speakerCount <= 8 ? (
                                <li
                                  className={`items-center ${
                                    speakerCount > 6
                                      ? "hidden 2xl:flex"
                                      : "flex"
                                  }`}
                                >
                                  {image ? (
                                    <Image
                                      image={image}
                                      objectFit="cover"
                                      objectPosition="top"
                                      imgClassName="rounded-full"
                                      className="w-11 h-11 bg-gray-200 rounded-full flex-shrink-0 mr-2 lg:mr-3"
                                    />
                                  ) : (
                                    <UserCircleIcon className="w-11 h-11 bg-gradient-to-br from-blue-100/70 to-gray-200 rounded-full p-2 text-gray-500/70 flex-shrink-0 mr-2 lg:mr-3" />
                                  )}
                                  <div className="flex-shrink">
                                    {name && (
                                      <div className="text-sm font-medium">
                                        {parse(name)}
                                      </div>
                                    )}
                                    {title && (
                                      <div className="text-sm text-gray-600">
                                        {parse(title)}
                                      </div>
                                    )}
                                  </div>
                                </li>
                              ) : null}
                            </>
                          )
                        })}
                      </>
                    )
                  })}
                </ul>
                <div className="">
                  <span className="2xl:hidden">
                    {speakerCount > 6
                      ? `+ ${speakerCount - 6} Speaker${
                          speakerCount > 7 ? "s" : ""
                        }`
                      : null}
                  </span>
                  <span className="hidden 2xl:inline">
                    {speakerCount > 8
                      ? `+ ${speakerCount - 8} Speaker${
                          speakerCount > 9 ? "s" : ""
                        }`
                      : null}
                  </span>
                </div>
              </>
            ) : null}
            {hit.topics && (
              <ul className="flex flex-wrap -mx-3 mt-4">
                {hit.topics.map(topic => (
                  <li className="flex mx-3 my-1 uppercase text-xs tracking-wider items-center">
                    <BookmarkIcon className="w-5 h-5 mr-1 text-gray-500" />
                    {parse(topic.title)}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </Link>
      </div>
    </div>
  )
}

const HitsInIndex = ({ index }) => {
  return (
    <Index indexName={index.name}>
      <Hits className="Hits" hitComponent={PageHit} />
    </Index>
  )
}

const SearchResult = ({ indices, displayLocalTimeZone }) => {
  displayLocalTimeZoneGlobal = displayLocalTimeZone
  return (
    <div>
      {indices.map(index => (
        <HitsInIndex index={index} key={index.name} />
      ))}
    </div>
  )
}

export default SearchResult
