import React from "react"
import { connectSearchBox } from "react-instantsearch-dom"
import { SearchIcon, XIcon } from "@heroicons/react/outline"

export default connectSearchBox(
  ({ refine, currentRefinement, className, onFocus, placeholder }) => (
    <form
      className={
        className +
        "relative flex flex-row items-center mb-0 bg-white shadow-2xl-around px-2 py-2 rounded-lg border-2 border-blue border-opacity-50 shadow-spread"
      }
      onSubmit={event => {
        event.preventDefault()
      }}
    >
      <SearchIcon className="h-6 w-6 text-gray-600 mr-2" aria-hidden="true" />
      <input
        className="w-full h-auto cursor-text border-none focus:outline-none focus:ring-0"
        type="text"
        placeholder={placeholder || "Search"}
        aria-label={placeholder || "Search"}
        onChange={e => refine(e.target.value)}
        value={currentRefinement}
        onFocus={onFocus}
      />
      <button aria-label="Reset query" onClick={() => refine("")}>
        <XIcon className="h-6 w-6 text-gray-600 hover:text-gray-900 p-1" />
      </button>
    </form>
  )
)
