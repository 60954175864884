import React from "react"
import { graphql } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Sections from "../components/sections"
import Breadcrumbs from "../components/breadcrumbs"

const PageTemplate = ({ data: { page } }) => {
  const customSections = page?.customSections

  // let breadcrumbs = [{ name: "Home", uri: "/" }, { name: page?.title }]
  return (
    <Layout isHomePage uri={page.uri}>
      <Seo
        title={page.seo?.title || page.title}
        description={page.seo?.metaDesc || page.content}
        image={page.seo?.opengraphImage?.localFile?.publicURL}
      />
      {page.uri !== "/" ? (
        <div className="container flex flex-wrap justify-between items-center">
          <Breadcrumbs />
        </div>
      ) : null}

      {page.titleOptions?.displayTitle || page.content ? (
        <section>
          <div className="container pt-10">
            {page.title && page.titleOptions?.displayTitle ? (
              <h1>{parse(page.title)}</h1>
            ) : null}
            {page.content && (
              <div className="parsed-content overflow-x-auto mt-8 mb-12">
                {parse(page.content)}
              </div>
            )}
          </div>
        </section>
      ) : null}

      {customSections?.sections && (
        <Sections sections={customSections.sections} />
      )}
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query WordPressPage($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      uri
      content
      seo {
        title
        metaDesc
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      titleOptions {
        displayTitle
      }
      customSections {
        sections {
          ... on WpPage_Customsections_Sections_Schedule {
            fieldGroupName
          }
          ... on WpPage_Customsections_Sections_Speakers {
            fieldGroupName
          }
          ... on WpPage_Customsections_Sections_Sponsors {
            fieldGroupName
            sponsorGroups {
              heading
              # size
              sponsors {
                ... on WpSponsor {
                  title
                  featuredImage {
                    node {
                      altText
                      publicUrl
                      localFile {
                        extension
                        childImageSharp {
                          gatsbyImageData(width: 256)
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Customsections_Sections_Team {
            fieldGroupName
            heading
            teamGroups {
              heading
              teamMembers {
                title
                teamMember {
                  ... on WpSpeaker {
                    name: title
                    featuredImage {
                      node {
                        altText
                        publicUrl
                        localFile {
                          extension
                          childImageSharp {
                            gatsbyImageData(width: 256)
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Customsections_Sections_Content {
            content
            fieldGroupName
          }
          ... on WpPage_Customsections_Sections_RoomsAndSessions {
            fieldGroupName
          }
        }
      }
    }
  }
`
